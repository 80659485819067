<template>
    <v-app class="login-page">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4 class="d-flex" style="max-width: 500px;flex-direction: column;align-items: center;">
                        <h1 style="color: #fff">404 - Page Not Found!</h1>
                        <router-link to="/">
                            <v-btn color="primary">Back to Home</v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'Error404',
        data: () => ({

        }),
        mounted() {

        },
        methods: {

        }

    }
</script>
